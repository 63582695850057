<script>
import { EPMC_FUNDERS as funders } from '@/config'

export default {
  metaInfo() {
    return {
      title: 'RSS Feeds - Tools - Europe PMC',
    }
  },
  data() {
    return {
      funders,
    }
  },
}
</script>
<template>
  <div id="rss-feeds-page">
    <div id="rss-feeds-content" class="grid-row">
      <div class="col-11">
        <h1>RSS Feeds</h1>
        <ul>
          <li>
            <i class="fa fa-rss" />
            <a href="http://blog.europepmc.org/feeds/posts/default"
              >Europe PMC Blog</a
            >
          </li>
        </ul>

        <h2>Articles supported by Europe PMC funders</h2>
        <ul>
          <li>
            <i class="fa fa-rss" />
            <a href="/Funders/RSS/MostViewedArticles.xml"
              >Top 10 viewed articles on Europe PMC in the last 7 days</a
            >
          </li>
          <li>
            <i class="fa fa-rss" />
            <a href="/Funders/RSS/AllFunders.xml"
              >All latest articles added to Europe PMC in the last 7 days</a
            >
          </li>
          <li>
            <i class="fa fa-rss" />
            <a href="/Funders/RSS/NewJournals.xml"
              >Latest journal titles added to Europe PMC in the last 60 days
            </a>
          </li>
        </ul>

        <h2>
          Latest articles added to Europe PMC by funder in the last 30 days
        </h2>
        <ul>
          <li v-for="funder in funders" :key="funder.name">
            <i class="fa fa-rss" />
            <a :href="funder.rss">{{ funder.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#rss-feeds-content {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
  ul {
    list-style: none;
    i {
      margin-right: $base-unit;
    }
  }
}
</style>
